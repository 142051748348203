<template>
  <div class="task_hall_container">

    <div class="task_hall_title">
      <div class="logo">
        <img src="@/assets/images/task-manager.png" alt="任务管理">
      </div>
      <div style="margin-left: 10px;">任务管理</div>
    </div>

    <!-- 表格面板 -->
    <el-tabs
      v-model="activeName"
      class="task_hall_table">
      <el-tab-pane
        label="任务审核"
        name="first">
        <audit-table></audit-table>
      </el-tab-pane>
      <el-tab-pane
        label="进行中的任务"
        name="second">
        <under-way-table></under-way-table>
      </el-tab-pane>
      <el-tab-pane
        label="已完成的任务"
        name="third">
        <complete-table></complete-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import AuditTable from './components/audit-table.vue'
import CompleteTable from './components/complete-table.vue'
import UnderWayTable from './components/under-way-table.vue'

export default {
  data () {
    return {
      activeName: 'first'
    }
  },
  components: {
    AuditTable,
    UnderWayTable,
    CompleteTable
  }
}
</script>

<style lang="scss" scoped>
.task_hall_container {
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .task_hall_title {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
