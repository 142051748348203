<template>
  <div>
    <el-table
      :data="tableList"
      class="table_border"
      v-loading="isLoading"
      :cell-style="cellStyle"
      @row-click="handleToUpdate">
      <el-table-column
        label="任务编号"
        width="80"
        :index="indexMethod"
        type="index">
      </el-table-column>
      <el-table-column
        label="任务名称"
        show-overflow-tooltip
        prop="title">
      </el-table-column>
      <el-table-column
        label="任务奖励"
        prop="amount">
      </el-table-column>
      <el-table-column label="任务级别">
        <template slot-scope="scope">
          <el-tag
            :type="{
              [TASK_LEVEL.COMMON]: 'success',
              [TASK_LEVEL.GENERAL]: 'info',
              [TASK_LEVEL.COMPLEX]: 'warning',
              [TASK_LEVEL.DIFFICULTY]: 'danger'
            }[scope.row.level]">
            {{
              {
                [TASK_LEVEL.COMMON]: '普通',
                [TASK_LEVEL.GENERAL]: '一般',
                [TASK_LEVEL.COMPLEX]: '复杂',
                [TASK_LEVEL.DIFFICULTY]: '困难'
              }[scope.row.level]
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="任务时限">
        <template slot-scope="scope">
          <span>{{scope.row.timeLimit}}天</span>
        </template>
      </el-table-column>
      <el-table-column
        width="220"
        label="任务截止时间（任务提交时限）"
        prop="deadline">
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <pagination
      @paginationChange="paginationChange"
      :pageInfo="pageInfo">
    </pagination>
  </div>
</template>

<script>
import Pagination from '@/components/pagination/index.vue'
import api from '@/api/index'
import globalVar from '@/configs/globalVar'

export default {
  components: {
    Pagination
  },
  data () {
    return {
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 20
      },
      isLoading: false,
      tableList: []
    }
  },
  computed: {
    TASK_LEVEL () {
      return globalVar.TASK_LEVEL
    }
  },
  created () {
    this.getTaskList()
  },
  methods: {
    cellStyle () {
      return 'height: 48px; padding: 0px;'
    },
    paginationChange (newNum) {
      this.pageInfo.pageNum = newNum
      this.getTaskList()
    },
    indexMethod (index) {
      // 当前页数 - 1 * 每页数据条数 + index + 1
      return (this.pageInfo.pageNum - 1) * this.pageInfo.pageSize + index + 1
    },
    // 已完成的任务详情
    handleToUpdate (row) {
      this.$router.push({
        path: `/admin/update-awards/${row.id}`
      })
    },
    // 获取任务列表
    getTaskList () {
      this.isLoading = true
      api.adminTaskList({
        status: '4,7',
        ...this.pageInfo
      }).then(res => {
        if (res.data.code === 0) {
          const { total, list, currentPage } = res.data.data
          this.tableList = list
          this.pageInfo.total = total
          this.pageInfo.pageNum = currentPage
        } else {
          this.$message.error('获取信息失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table_border {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
}
</style>
